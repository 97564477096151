import template from './users.html'

class UsersPageVM
{
	constructor (page)
	{
		this.page = page;
		this.active_users = ko.observableArray([]);
		this.search_string = ko.observable('');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.sort_field = ko.observable('username');
		this.sort_order = ko.observable('ASC');
	}

	btn_page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click ()
	{
		this.page.updateData();
	}

	btn_reset_password_click (row)
	{
		this.page.reset_password(row.username);
	}

	async btn_switch_user_click (row)
	{
		//TODO
		let obj = {
			guid: row.guid,
			user_id: row.user_id,
			username: row.username
		};

		let response = await fetch(`/api/session/switch-user`, { 
			method: 'POST',
			headers: {'content-type': 'application/json'},
			body: JSON.stringify(obj)
		});

		let data = await response.json();

		if (data.status == 'ERROR')
			Grape.alert_api_error(data);
		else
			window.location.reload();
	}

	btn_edit_click (row)
	{
		this.page.edit_user(row);
	}

	async btn_deactivate_click (row)
	{
		row.active = false;

		let response = await fetch('/api/user', {
			method: 'POST',
			body: JSON.stringify({
				username: row.username,
				active: row.active
			})
		});
		this.page.updateData();
	}

	async btn_activate_click (row)
	{
		row.active = true;
		let response = await fetch('/api/user', {
			method: 'POST',
			body: JSON.stringify({
				username: row.username,
				active: row.active
			})
		});
		this.page.updateData();
	}

	//TODO: Add HTML PART OF THIS
	/*			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field()
	get_status_icon (row)
	{
		if (row.auth_server)
			return "fa-cloud-upload";
		else if (!row.active)
			return "fa-times";
		return "";
	}

	getStatusTooltip (row)
	{
		if (row.auth_server)
			return "Remote User";
		else if (!row.active)
			return "User Disabled";
		return "";
	}
	*/

	get_last_login_display (row)
	{
		if (!row.auth_last_login)
			return 'Never';
		return moment(row.auth_last_login).fromNow();
	}

	get_formatted_roles (user)
	{
		return ko.pureComputed(() => {
			let roleNames = user.role_names || [];
			let assignedRoleNames = user.assigned_role_names || [];
			return roleNames.map(r => {
				if (assignedRoleNames.indexOf(r) >= 0)
					return `<span style="font-weight: bold;">${r}</span>`;
				else
					return `<span style="color: #afafaf; font-style: italic;">${r}</span>`;
			}).join(', ');
		}, this);
	}

	sort_column (column)
	{
		if (this.sort_field() === column) {
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		} else {
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}
}

class UsersPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new UsersPageVM(this);

		const searchInput = document.getElementById('searchInput');
		searchInput.addEventListener('keyup', (event) => {
			if (event.key === 'Enter')
				this.updateData();
		});
	}

	init ()
	{
		document.title = 'Dashboard - Users';
		this.updateData();
	}

	async updateData ()
	{
		let users_result = {
			table: 'v_users', 
			schema: 'grape',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [],
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field()
		}

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '') 
		{
			users_result.filter.push({ 
				field: 'username', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.search_string()}%` 
			});
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			users_result.limit = this.viewModel.current_page_size();
			users_result.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		let users = await Grape.fetches.getJSON('/api/record', users_result);

		if (users.status != 'ERROR')
		{
			this.viewModel.active_users(users.records);
			this.viewModel.page_count(Math.floor(users.total/users.limit)+1);
		}
		else
			throw new Error(users.message || users.code);
	}

	btnAddUser_click ()
	{
		this.edit_user(null);
	}

	async reset_password (username)
	{
		try
		{
			let result = await Grape.dialog.open('DataInputDialog', {
				fields: [{
					name: 'new_password', 
					type: 'password', 
					label: 'New Password'
				}],
				title: 'Reset Password',
				message: 'Please provide password for ' + username,
				data: {}
			});

			let response = await fetch('/api/user', {
				method: 'POST',
				body: JSON.stringify({
					username,
					password: result.new_password
				})
			});
			let data = await response.json();

			if (data.status == 'ERROR')
				throw new Error(data.message);
			else
				Grape.alerts.alert({type: 'success',  title: 'Success', message: 'User password has been reset.'});
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({type: 'error', title: 'Error',  message: error.message });
		}
	}

	async edit_user (row)
	{
		let user_info = await Grape.dialog.open('UserEdit', row);
		if (!user_info)
			return;

		try
		{
			let response = await fetch('/api/user', {
				method: 'POST',
				body: JSON.stringify(user_info)
			});
			let data = await response.json();

			if (data.status == 'OK')
			{
				if (row)
				{
					Grape.alerts.alert(
					{
						type: 'success',
						message:  'Profile details updated',
						title: user_info.username
					});
				}
				else
				{
					Grape.alerts.alert(
					{
						type: 'success',
						message:  'New user added',
						title: user_info.username
					});
				}

				this.updateData();
			}
			else
				throw new Error(data.message);
		} catch (error) {
			console.error(error);
			Grape.alerts.alert({type: 'error', title: 'Error',  message: error.message });
		}
	}
}

export default {
	page_class: UsersPage,
	template: template,
	name: 'users',
	title: 'Users',
	page_id: 'dashboard.users',
	icon: 'fa fa-users'
}
