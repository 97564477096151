import template from './data_input_dialog.html';

class DataInputVM
{
	constructor (dialog)
	{
		this.dialog = dialog;
	}

	btn_close_click ()
	{
		this.dialog.close(null); 
	}

	btn_save_click ()
	{
		let obj = Grape.retrieve_fields('#div_data_input_fields', this.dialog.fields);
		
		obj = _.extend(this.dialog.bindings.data || {}, obj);
		this.dialog.close(obj);
	}
}

class DataInputDialog
{
	constructor (bindings)
	{
		this.viewModel = new DataInputVM(this);
		this.fields = [];
		this.bindings = bindings;
		this.name = 'DataInput';
		this.object_to_display = bindings;

		this.init();
	}

	init ()
	{
		let fields = this.bindings.fields;

		if (this.bindings.title)
			$("#span_header").html(this.bindings.title);

		if (this.bindings.message)
			$("#span_message").html(this.bindings.message);
		else
			$(".grape_dialog_subheading").remove();

		if (this.bindings.width)
			$(".ps-dialog-dialog").css("width", this.bindings.width);

		if (this.bindings.button_save_text)
			$("#btnSaveText").html(this.bindings.button_save_text);

		if (this.bindings.header_icon)
			$("#header_icon").html('<span class="fa '+ this.bindings.header_icon +' grape_dialogheader_circle_icon"></span>  ');
		else
			$("#header_icon").html('<span class="fa fa-align-left grape_dialogheader_circle_icon"></span>  ');

		let data = this.bindings.data || {};

		fields.forEach((f) => {
			if (f.default_value == '' && data[f.name])
				f.default_value = data[f.name];
		});
		
		this.fields = fields;

		Grape.render_fields('#div_data_input_fields', fields);
	}
}

export default {
	name: 'DataInputDialog',
	dialog_class: DataInputDialog,
	template: template
}
