
import template from './include-exclude-list.html';


class viewModel
{
	constructor(options) {
		this.page = options.page || null;

		if (ko.isObservable(options.allItems))
			this.allItems = options.allItems;
		else
			this.allItems = ko.observableArray(options.allItems || []);

		if (ko.isObservable(options.selectedItems))
			this.selectedItems = options.selectedItems;
		else
			this.selectedItems = ko.observableArray(options.selectedItems || []);

		// calculated
		this.availableItems = ko.observableArray([]);
		this.selectedAvailableItems = ko.observableArray();

		this.selectedCurrentItems = ko.observableArray();

		this.selectedItems.subscribe((nv) => {
			let ar = [];
			if (nv && nv.length)
				ar = this.allItems().filter(function(i) { return (nv.indexOf(i) == -1); });
			else
				ar = this.allItems();
			this.availableItems(ar);
		});

		this.allItems.subscribe((nv) => {
			let ar = [];
			let selected = this.selectedItems() || [];
			ar = nv.filter((x) => selected.indexOf(x) == -1);
			this.availableItems(ar);
		});

	}

	btnAdd_click()
	{
		let curr = this.selectedItems();
		if (!curr)
			curr = [];
		this.selectedItems(curr.concat(this.selectedAvailableItems()));
	}

	btnRemove_click()
	{
		let curr = this.selectedItems();
		let remove = this.selectedCurrentItems();
		curr = curr.filter((x) => (!remove.includes(x)));
		this.selectedItems(curr);
	}
}

export default {
	name: 'include-exclude-list',
	module_type: 'ko',
	template: template,
	viewModel: viewModel
};


