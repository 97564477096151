
// DIALOGS
import recover_pw_dialog from './recover_password.js';
import user_edit from './user_edit.js';
import setting_edit from './setting_edit.js';
import view_log from './viewlog.js';
import new_password_dialog from './new_password_dialog.js';
import view_session from './view_session.js';
import data_input_dialog from './data_input_dialog.js';

let dialogs = [
	recover_pw_dialog,
	view_session,
	user_edit,
	setting_edit,
	view_log,
	new_password_dialog,
	data_input_dialog
];

export default dialogs;
