
// COMPONENTS
import loginForm from './login-form.js';
import includeExcludeList from './include-exclude-list.js';

export default [
	loginForm,
	includeExcludeList
];

