
// PAGES 
import login_page from './login.js';
import account_page from './account.js';
import apikeys from './api-keys.js';
import dashboard from './dashboard.js';
import grapeuilogout_page from './grapeui_logout.js';

import grapeui_password_reset_page from './new_password.js';

export default [
	login_page,
	grapeuilogout_page,
	grapeui_password_reset_page,
	account_page,
	apikeys,
	dashboard
];

