import template from './access_roles.html'

class AccessRolesVM
{
	constructor (dialog, page)
	{
		this.dialog = dialog;
		this.page = page;
		this.roles = ko.observableArray([]);
		this.search_string = ko.observable('');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.sort_field = ko.observable('role_name');
		this.sort_order = ko.observable('ASC');
	}

	a_edit_click (row)
	{
		this.page.EditDialog(row);
	}

	btnAdd_click()
	{
		this.dialog.btnAdd_click();
	}

	btn_page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click ()
	{
		this.page.updateData();
	}

	sort_column (column)
	{
		if (this.sort_field() === column) {
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		} else {
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}

	format_roles (roles)
	{
		if (typeof roles === 'string')
			return roles.replace(/,/g, ', ');
		else if (Array.isArray(roles))
			return roles.join(', ');
		else
			return "";
	}
}

class AccessRolesPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new AccessRolesVM(this, this);
		this.access_roles = [];

		const searchInput = document.getElementById('searchInput');
		searchInput.addEventListener('keyup', (event) => {
			if (event.key === 'Enter')
				this.updateData();
		});
	}

	async init () 
	{
		document.title = 'Dashboard - Access Roles';

		let roles = await Grape.cache.fetch('AccessRoles');
		let rolenames = roles.map((x) => x.role_name);
		this.access_roles = rolenames;
	}

	async updateData ()
	{
		let options = {
			table: 'v_access_roles',
			schema: 'grape',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [],
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field()
		}

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '') 
		{
			options.filter.push({ 
				field: 'role_name', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.search_string()}%` 
			});
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		let result = await Grape.fetches.getJSON('/api/record', options);

		if (result.status != 'ERROR')
		{
			this.viewModel.roles(result.records);
			this.viewModel.page_count(Math.floor(result.total/result.limit)+1);
		}
		else
			throw new Error(result.message || result.code);
	}

	btnAdd_click ()
	{
		this.EditDialog(null);
	}
	
	async EditDialog (row)
	{
		let name_editable = false;
		if (!row)
		{
			var row = {role_name: '', membership: [], assigned_roles: []};
			name_editable = true;
		}

		if (!row.assigned_roles)
			row.assigned_roles = [];

		let fields = [
			{
				name: 'role_name', 
				type: (name_editable ? 'text' : 'text_readonly'), 
				label: 'Role Name', 
				default_value: row['role_name']
			},
			{
				name: 'assigned_roles', 
				type: 'multiselect', 
				label: 'Assigned Roles', 
				list: this.access_roles, 
				default_values: row.assigned_roles
			}
		];

		try
		{
			let data = await Grape.dialog.open('DataInputDialog', {
					fields: fields,
					title: (name_editable ? 'Add new access role' : 'Edit access role'),
					data: row
				},
				{}
			);

			if (!data)
				return;

			let assigned_roles_idx = data.assigned_roles.indexOf(data.role_name);
			if (assigned_roles_idx != -1)
			{
				row.assigned_roles.splice(assigned_roles_idx);
				throw new Error('Cannot assign role to itself');
			}

			let body = { role_name: data.role_name, assigned_roles: data.assigned_roles };
			let response = await fetch('/api/access-role', {
				method: 'POST', 
				headers: {'content-type': 'application/json'}, 
				body: JSON.stringify(body)
			});
			let resdata = await response.json();

			if (response.ok)
			{
				if (name_editable)
					Grape.alerts.alert({ type: 'success', message: 'Access role added', title: 'Success' });
				else
					Grape.alerts.alert({ type: 'success', message: 'Access role updated', title: 'Success' });

				Grape.cache.invalidate('AccessRoles');
				this.updateData();
			}
			else
				throw new Error(resdata.message);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', message: `Access role error: ${error.message}`, title: 'Error' });
			console.error(error);
		}
	}
}

//Assign Page(HTML/JS) to Grape
export default {
	route: '[/grape-ui/grape_settings/]access_roles',
	page_class: AccessRolesPage,
	container: 'divpagecontainer',
	template: template,
	name: 'access_roles',
	title: 'Access Roles',
	page_id: 'dashboard.access_roles',
	icon: 'fa fa-group'
}
