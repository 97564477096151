import template from './networks.html'

class NetworksPageVM
{
	constructor (page)
	{
		this.page = page;
		this.active_networks = ko.observableArray([]);
		this.search_string = ko.observable('');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.sort_field = ko.observable('description');
		this.sort_order = ko.observable('ASC');
	}

	a_edit_click (row)
	{
		this.page.EditDialog(row);
	}

	a_delete_click (row)
	{
		this.page.DeleteDialog(row);
	}

	btn_page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click ()
	{
		this.page.updateData();
	}

	sort_column (column)
	{
		if (this.sort_field() === column) {
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		} else {
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}
}

class NetworksPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new NetworksPageVM(this);

		const searchInput = document.getElementById('searchInput');
		searchInput.addEventListener('keyup', (event) => {
			if (event.key === 'Enter')
				this.updateData();
		});
	}

	init ()
	{
		document.title = 'Dashboard - Networks';
		this.updateData();
	}

	async updateData ()
	{
		let network_result = {
			table: 'network', 
			schema: 'grape',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [],
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field()
		}

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '') 
		{
			network_result.filter.push({ 
				field: 'description', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.search_string()}%` 
			});
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			network_result.limit = this.viewModel.current_page_size();
			network_result.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		let network = await Grape.fetches.getJSON('/api/record', network_result);

		if (network.status != 'ERROR')
		{
			this.viewModel.active_networks(network.records);
			this.viewModel.page_count(Math.floor(network.total/network.limit)+1);
		}
		else
			throw new Error(network.message || network.code);
	}

	btnAdd_click ()
	{
		this.EditDialog(null);
	}

	async DeleteDialog (row)
	{
		if (!(await Grape.alerts.confirm({type: 'warning', title: 'Confirm deletion', message: 'Are you sure you want to delete this network?'})))
			return;

		await Grape.tables.delete({schema: 'grape', table: 'network', filter: {network_id: row.network_id}});
		Grape.alerts.alert({type: 'success', title: 'Network deleted', message: 'Network was deleted successfuly'});
		this.updateData();
	}
	
	async EditDialog (row)
	{
		let title = 'Add new network';
		if (!row)
			var row = {description: '', address: ''};
		else
			title = 'Edit network';

		let fields = [
			{name: 'description', type: 'text', label: 'Network Description', default_value: ''},
			{name: 'address', type: 'text', label: 'Network Address', default_value: ''}
		];

		let data = await Grape.dialog.open('DataInputDialog', {fields, title, data: row});

		if (!data)
			return;
						
		if (data.network_id)
		{
			await Grape.tables.update({
				schema: 'grape',
				table: 'network',
				values: data,
				returning: 'network_id',
				filter: {network_id: data.network_id}});

			Grape.alerts.alert({type: 'success', title: 'Network updated', message: 'Network information was updated successfuly'});
		}
		else
		{
			await Grape.tables.insert({
				schema: 'grape',
				table: 'network',
				values: data,
				returning: 'network_id'
			});
			Grape.alerts.alert({type: 'success', title: 'New network added', message: 'Network was added successfuly'});
		}

		this.updateData();
	}
}

export default {
	route: '[/grape-ui/grape_settings/]networks',
	page_class: NetworksPage,
	container: 'divpagecontainer',
	template: template,
	name: 'networks',
	title: 'Networks',
	page_id: 'dashboard.networks',
	icon: 'fa fa-code-fork'
}
