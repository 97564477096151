import template from './login.html';

//VIEWMODEL: Data structure directly accessible on html page 
class LoginPageViewModel 
{
	constructor (page){
		this.page = page;
		this.service_name = ko.observable();
		this.show_register_button = ko.observable(false);
		this.show_forgot_password_button = ko.observable(false);
	}
	
	on_login (session) {
		this.page.after_login(session);
	};
	
	on_failure (err) {
		if (err.code == -1 || err.code == -1002)
		{
			Grape.alerts.alert({
				type: 'error', 
				message: 'The password you entered is wrong. Please try again.', 
				title: 'Invalid password'
			});
		}
		else if (err.code == -2 || err.code == -1001)
		{
			Grape.alerts.alert({type: 'error', 'message': 'The username you provided does not exist in the system', title: 'No such account'});
		}
		else if (err.code == -3 || err.code == -503)
		{
			Grape.alerts.alert({type: 'error', 'message': 'This user is currently blocked from gaining access to this service', title: 'User blocked'});
		}
		else if (err.code == -6)
		{
			Grape.alerts.alert({type: 'warn', message: 'You need to create a new password before logging in.', title: 'Password Reset'});
		}
		else if (err.code == -80)
		{
			Grape.alerts.alert({type: 'error', message: 'You need to enter a username and password'});
		}
		else if (err.code == -600)
		{
			Grape.alerts.alert({type: 'error', message: 'The OTP code that you entered is wrong or has expired. Please try again.', title: 'OTP Error'});
		}
		else
		{
			Grape.alert_api_error(err.result || err);
		}
	};
	
	//DIALOG: Forgot Password
	on_forgot_password_click () {
		Grape.show_dialog(
			'RecoverPassword', 
			{},
			{ 
				onClose: (result) => { 
					//Grape.clean_shortcuts('RecoverPassword');
					//Grape.shortcut(this, 'enter', this.login);
				} 
			});
	};

	//DIALOG: Register
	register_click () {
		//DIALOG / POPUP
		Grape.show_dialog('Registration');
	};

};

//PAGE: Handle Interaction with Page 
class LoginPage
{
	constructor(bindings){
		//var this = this;
		//this.this = this;
		this.name = 'LoginPage';
		this.bindings = bindings;

		this.viewModel = new LoginPageViewModel(this);
	}
	
	init () {
		document.title = 'Login';
		if (window.Grape.currentSession)
		{
			console.debug('Already logged in');
			this.after_login(window.Grape.currentSession);
			return;
		}

		if (window.Grape.dialogs['Registration'])
			this.viewModel.show_register_button(true);
		if (window.Grape.dialogs['RecoverPassword'])
			this.viewModel.show_forgot_password_button(true);
		
		if (window.Grape.public_settings && window.Grape.public_settings.service_name)
			this.viewModel.service_name(window.Grape.public_settings.service_name);
	};

	after_login (session) {
		if (this.bindings.redirect_url)
		{
			window.location = this.bindings.redirect_url;
			location.reload(true);
		}
		else if (this.bindings.rr) // redirect route
		{
			let route = decodeURIComponent(this.bindings.rr);
			console.debug('LOGIN PAGE ROUTING TO', route);
			window.Grape.navigate(route);
		}
		else
		{
			window.Grape.navigate_to_default();
		}
	};



};

export default {
	route: '[/]grape-ui/login',
	page_class: LoginPage,
	template: template
};
