import template from './viewlog.html';

/**
 * View Model
 */
class DialogViewModel
{
	/**
	 * ViewModel constructor.
	 * @param {DialogClass} page - Dialog class
	 */
	constructor (page)
	{
		this.page = page;
		this.log_entries = ko.observableArray([]);
		this.process_id = ko.observable(page.bindings.process_id);
		this.process_name = ko.observable(page.bindings.process_name);
		this.download_log_href = ko.computed(() => {
			return ['/grape/v2/log/download?process_name=', encodeURIComponent(this.process_name()), '&process_id=', encodeURIComponent(this.process_id())].join('');
		});
	}

	/**
	 * Handle click event for close button
	 */
	close_click ()
	{
		this.page.socket.close(1000);
		this.page.close(false);
	}
}

/**
 * Dialog
 */
class ViewLogDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this);
		this.name = 'ViewLogDialog';
		this.socket = null;

		this.init();
	}

	init ()
	{
		let url = null;
		if (window.location.protocol == 'http:')
			url = 'ws://' + window.location.host + '/grape/v2/log/tail';
		else if (window.location.protocol == 'https:')
			url = 'wss://' + window.location.host + '/grape/v2/log/tail';
		else
			throw new Error('Invalid protocol in window.location.protocol');

		url = url + '?process_name=' + encodeURIComponent(this.bindings.process_name) + '&process_id=' + this.bindings.process_id;

		this.socket = new WebSocket(url, 'json');

		this.socket.addEventListener('open', (event) => {
			console.log('socket is open');
		});

		this.socket.addEventListener('message',  (event) => {
			//let log_entries = self.viewModel.log_entries();
			let data = event.data;
			console.log('Message from server ', data);
			if (data instanceof Blob)
			{
				data.text().then((txt) => {
					console.log('TEXT:',txt);
					try {
						let msg = JSON.parse(txt);
						this.viewModel.log_entries.push(msg);
					} catch (err) {
						console.error(err);
					}
				}).catch((err) => {
					console.error(err);
				});
			}
			else
			{
				try {
					let msg = JSON.parse(event.data);
					this.viewModel.log_entries.push(msg);
				} catch (e) {
				}
			}
			//self.viewModel.log_entries(log_entries);
		});

		this.socket.addEventListener('close', (event) => {
			console.log('socket is closed');
		});
		
		this.socket.addEventListener('error', (event) => {
			console.log('socket error');
		});
	}
}

export default {
	name: 'ViewProcessLogDialog',
	dialog_class: ViewLogDialogClass,
	template: template
}
