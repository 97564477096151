import template from './dashboard.html';

class AdminDashboardVM
{
	constructor (page)
	{
		this.page = page;
		this.tabs = ko.observableArray(window.dashboard_items); 
		this.current_page_id = ko.observable();
	}

	load_tab (page_id)
	{
		window.localStorage.setItem('last_dashboard_page_id', page_id);
		const element = this.page.element.querySelector('#page_content');
		this.current_page_id(page_id);
		const page = Grape.pages.find_page_by_id(page_id);
		if (!page)
			throw new Error(`Page with id "${page_id}" not found`);
		Grape.pages.load_page(page, element, {}, {}, () => {});
	}
}

class AdminDashboardPage 
{
	constructor (bindings, element, page)
	{
		this.viewModel = new AdminDashboardVM(this);
		this.bindings = bindings;
		this.element = element;
	}
	
	async init ()
	{
		document.title = 'Dashboard';

		if (!window.Grape.currentSession)
		{
			let current_hash = encodeURIComponent('/grape-ui/admin/dashboard');
			document.location = '#/grape-ui/login?rr=' + current_hash;
			return;
		}

		if (window.localStorage.getItem('last_dashboard_page_id'))
			this.viewModel.load_tab(window.localStorage.getItem('last_dashboard_page_id'));
		else
			this.viewModel.load_tab('dashboard.overview');
	}

	updateData (v,e)
	{
	}

	async teardown ()
	{
	}
}

export default {
	route: '[/]grape-ui/admin/dashboard',
	page_class: AdminDashboardPage,
	template: template
}
