import overview from './overview.js';
import users from './users.js';
import access_roles from './access_roles.js';
import networks from './networks.js';
import sessions from './sessions.js';
import settings from './settings.js';

export default [
	overview,
        users,
        access_roles,
        networks,
        sessions,
	settings
];

