
import template from './login-form.html';

import GrapeLogin from '../lib/GrapeLogin.js';

/**
 * @name GrapeUILoginForm
 * @knockout
 *
 * @param {Object} options - Options
 * @param {GrapeUILoginForm~SuccessCB} options.on_login -
 * @param {GrapeUILoginForm~FailureCB} options.on_failure -
 */

/**
 * @name GrapeUILoginForm~SuccessCB
 * @function
 * @callback
 * @memberof GrapeUILoginForm
 */

/**
 * @name GrapeUILoginForm~FailureCB
 * @callback
 * @memberof GrapeUILoginForm
 *
 * @param {Object} error - Object with error details
 * @param {integer} error.code - -1 =
 * 				-2 =
 * 				-503 = User is not active
 * 				-600 = OTP is wrong
 * 				-1001 = User does not exist
 * 				-1002 = Invalid password
 * @param {string} error.message - Error message
 * @param {Object} error.error - Additional error data
 */

class viewModel
{
	constructor(options) {
		this.page = options.page || null;
		this.username = ko.observable('');
		this.password = ko.observable('');
		this.otpMethods = ko.observableArray([]);
		this.otpInputs = {};

		this.authentication_service = ko.observable();

		this.has_user_info = ko.observable(false);

		this.focusField = ko.observable('');

		this.is_loading = ko.observable(true);
		this.is_status_ok = ko.observable(false);
		this.is_status_error = ko.observable(false);

		this.loading_message = ko.observable('');
		this.ok_message = ko.observable('');
		this.error_message = ko.observable('');
		
		this.Login = new GrapeLogin();

		this.status_code = ko.observable(0);
		this.status_log = ko.observableArray();
		this.set_status = (code, message) => {
			this.status_code(code);
			this.status_log.push(message);

			this.is_loading(false);
			this.is_status_ok(false);
			this.is_status_error(false);

			if (code == 'READY')
			{
				this.is_status_ok(true);
				this.ok_message(message);
			}
			else if (code == 'BUSY')
			{
				this.is_loading(true);
				this.loading_message(message);
			}
			else
			{
				this.is_status_error(true);
				this.error_message(message);
			}
		};
		
		this.on_success = async (session) => {
			
			await Grape.UsersPlugin.setSession?.(session);

			if (options.on_login && typeof options.on_login === 'function')
				options.on_login(session);
		};

		this.on_failure = (error) => {
			if (options.on_failure && typeof options.on_failure === 'function')
				options.on_failure(error);
		};

		this.on_change_user_click = () => {
			this.Login.reset();

			localStorage.removeItem('r_encrypted_tgt_container');
			this.has_user_info(false);
			this.password('');
			this.otpMethods([]);
			
			this.focusField('username');

			
		};

		if (window.Grape.currentSession)
		{
			// already logged in
			this.on_success(window.Grape.currentSession);
		}
		else
		{
			this.set_status('READY', 'Ready to log into ' + window.Grape.config.public_settings.service_name);
			// CACHE: Get encrypted TGT from cache
			if (localStorage.getItem('r_encrypted_tgt_container') != null)
				this.Login.encrypted_tgt_container = JSON.parse(localStorage.getItem('r_encrypted_tgt_container'));
		}
	}

	async authRequest()
	{
		let username = this.username().trim();
		if (username == '')
		{
			Grape.alerts.alert({ type: 'error', message: 'You need to enter a username/email' });
			this.set_status('ERROR', 'Please enter your email address in the box below');
			return;
		}

		this.set_status('BUSY', 'Getting user information...');
		
		try {
			await this.Login.authRequest(this.username());
			this.set_status('READY', 'User information received.');

			if (this.Login.status == 'READY')
			{
				localStorage.setItem('encrypted_tgt_container', JSON.stringify(this.Login.encrypted_tgt_container));
				this.has_user_info(true);
				this.focusField('password');
				this.otpMethods(this.Login.userOtpMethods);
				for (let otp of this.otpMethods())
					this.otpInputs[otp] = ko.observable('');
			}
			
			if (this.Login.status == 'READY')
			{
				this.loginForm_submit();
			}
			else if (this.Login.status == 'REDIRECT')
			{
				this.set_status('BUSY', 'Redirecting...');
				if (this.Login.redirectURL)
					window.location = this.Login.redirectURL;
				else
				{
					let err = new Error('Invalid service params');
					throw err;
				}
			}
			else
			{
				this.set_status('ERROR', 'Unable to authenticate with the provided username/email (Login Status = "' + this.Login.status + '")');
			}
		} catch (err) {
			console.error(err);
			this.set_status('ERROR', 'An error occured during authentication request.');
		}
	}

	async loginForm_submit (form)
	{
		if (!(this.Login.encrypted_tgt_container))
		{
			let status = await this.authRequest();
			return false;
		}

		if (this.password() == '' || this.password() == null)
		{
			this.set_status('READY', 'Please enter your password');
			return false;
		}
		
		this.Login.password = this.password();

		if (this.otpMethods().length > 0)
		{
			let missing_otp = false;
			for (let otp of this.otpMethods())
			{
				if (this.otpInputs[otp]() == '')
				{
					missing_otp = true;
					this.focusField(otp);
				}
				else
				{
					this.Login.otp[otp] = this.otpInputs[otp]();
				}
			}

			if (missing_otp === true)
			{
				this.set_status('ERROR', 'Please enter your One-time PIN');
				return false;
			}
		}
		
		try {
			await this.Login.decryptTGTContainer();
		} catch (err) {
			console.warn(err);
			this.set_status('ERROR', 'Invalid password entered');
			return false;
		}

		let result = await this.Login.loginWithTGT();

		if (result.status == 'OK')
		{
			// try to save the password in the browser
			try {
				if (window.PasswordCredential)
				{
					let c = new PasswordCredential(document.getElementById('login-form'));
					navigator.credentials.store(c);
				}
			} catch (err) {
				console.warn('Error while saving credentials', err);
			}

			this.on_success(result);
		}
		return false;
	}
}

export default {
	name: 'grape-login-form',
	module_type: 'ko',
	template: template,
	viewModel: viewModel
};


