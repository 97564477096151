

class UserSettingValue
{
	#_setting_name;
	#_active;
	#_idx;
	#_tags = [];
	#_value;
	constructor(name)
	{
		this.#_setting_name = name;
		this.input = ko.observable();
	}
	get name() { return this.#_setting_name; }
	get active() { return this.#_active; }
	set active(value) { this.#_active = value; }
	get idx () { return this.#_idx; }
	set idx (value) { this.#_idx = value; }
	get tags () { return this.#_tags; }
	set tags (value) { this.#_tags = value; }
	get value () { return this.#_value; }
	set value (value) {
		this.#_value = value;
		this.input(this.#_value);
	}
}

class UserSettingMap 
{
	#_user_id;
	#_settings = new Map();
	constructor(user_id)
	{
		this.user_id = user_id;
	}

	get user_id () { return this.#_user_id; }
	set user_id (value) { this.#_user_id = value; }

	/**
	 * This function is used to add or update a setting and its properties.
	 * @param {String} name - Setting name
	 * @param {String} setting_params - Object containing fields active, idx, tags and/or value.
	 */
	set_setting(name, setting_params)
	{
		if (!this.#_settings.has(name))
			this.#_settings.set(name, new UserSettingValue(name));
		const setting = this.#_settings.get(name);
		if (setting_params.hasOwnProperty('active'))
			setting.active = setting_params['active'];
		if (setting_params.hasOwnProperty('idx'))
			setting.idx = setting_params['idx'];
		if (setting_params.hasOwnProperty('tags'))
			setting.tags = setting_params['tags'];
		if (setting_params.hasOwnProperty('value'))
			setting.value = setting_params['value'];
	}

	/**
	 * Set multiple settings at once.
	 */
	set_settings(settings)
	{
		if (Array.isArray(settings)) // Array of objects with 'name' and other fields for settings.
		{
			for (let v of settings)
				if (v instanceof Object)
				{
					if (v.hasOwnProperty('setting_name'))
						this.set_setting(v.setting_name, v);
					else if (v.hasOwnProperty('name'))
						this.set_setting(v.name, v);
				}
		}
		else if (settings instanceof Object) // Object of setting name/value pairs
		{
			for (let [k, v] of Object.entries(settings))
				this.set_setting(k, v);
		}
		else
		{
			throw new Error('Unknown type for settings sent to set_settings() function');
		}
	}

	
	/**
	 * Set single value
	 * @param {String} name - Name of setting
	 * @param {String} value - Value of setting
	 */
	set_value(name, value)
	{
		if (!this.#_settings.has(name))
			this.#_settings.set(name, new UserSettingValue(name));
		const setting = this.#_settings.get(name);
		setting.value = value;
	}

	/**
	 * Set multiple values at once.
	 */
	set_values(values)
	{
		if (Array.isArray(values)) // Array of objects with 'name' and 'value' fields
		{
			for (let v of values)
				if (v instanceof Object &&
					v.hasOwnProperty('name') && 
					v.hasOwnProperty('value')
				)
					this.set_value(v.name, v.value);
		}
		else if (values instanceof Object) // Object of setting name/value pairs
		{
			for (let [k, v] of Object.entries(values))
				this.set_value(k, v);
		}
		else
		{
			throw new Error('Unknown type of values sent to set_values() function');
		}
	}

	/**
	 * Get setting
	 */
	get(name)
	{
		return this.#_settings.get(name);
	}
	/**
	 * Get value
	 */
	get_value(name)
	{
		return this.#_settings.get(name)?.value;
	}

	/**
	 * Returns iterator for all settings
	 */
	entries()
	{
		return this.#_settings.entries();
	}

	/**
	 * Get sorted, observable array of all entries
	 */
	get_sorted_array()
	{
		const arr = [];
		for (let [k, v] of this.entries())
			arr.push(v);
		const a = arr.sort((x, y) => x.idx - y.idx);
		return a.map((v) => ko.observable(v));
	}
}

export default UserSettingMap;

