
import components from './components/index.js';
import dialogs from './dialogs/index.js';
import pages from './pages/index.js';
import dashboard_pages from './dashboard-pages/index.js';
import appcaches from './caches/index.js';

import package_json from '../package.json';

import './css/grape-ui-users.css';

import jsSHA from 'jssha';
import Plugin from './lib/Plugin.js';

window.JSSHA = jsSHA;

(function() {

	window.dashboard_items = window.dashboard_items || [];

	window.Grape.modules.push(package_json);

	Grape.utils.register_caches(appcaches);

	components.forEach(function (component) {
		if (!component.module_type || component.module_type == 'ko')
		{
			ko.components.register(component.name, {
				template: component.template,
				viewModel: component.viewModel
			});
		}
	});

	dialogs.forEach(function (dialog) {
		window.Grape.dialog.dialogs[dialog.name] = {
			pageClass: dialog.dialog_class,
			template: dialog.template
		};
	});

	for (let page of dashboard_pages)
	{
		Grape.pages.add_page(page);
		if (window.dashboard_items)
			window.dashboard_items.push(page);
	}

	pages.forEach(function (page) {
		window.Grape.route(page.route, page);
	});

	Grape.plugins.register(Plugin);
})();

if (!window.UserAccountPlugins)
	window.UserAccountPlugins = [];

